.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #077140;
  color: black;
  padding: 10px 10px; /* Ajusta el espacio interno */
  position: relative; /* Añadido para el posicionamiento */
  transition: background-color 0.3s ease-in-out;
  font-family: 'Montserrat', sans-serif;
}
.navbar-brand a{
  text-decoration: none;
  color: black;font-weight: bold;
  font-size: 1em; /* Ajusta el tamaño del texto de los enlaces */
  font-family: 'Montserrat', sans-serif;
  transition: color 0.3s ease-in-out;
  
  margin: 0 10px; 
}
.navbar-links a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em; /* Ajusta el tamaño del texto de los enlaces */
  font-family: 'Montserrat', sans-serif;
  transition: color 0.3s ease-in-out;
  margin: 0 10px; 
}
.navbar-links a:hover {
  color: black;
}

@media (max-width: 500px) {

  .navbar-links a { 
    margin-top: 1px;
  }}