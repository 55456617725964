.titleeee {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
    background-color: #077140;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
}
.padree{
    background-image: url('../images/fondo.jpg');
    padding-bottom: 50px;
    padding-top: 50px;
}
.cf{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #077140;
}
.titless {
    font-size: 24px;
    margin: 0;
}
.resultadoini{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.children-titlesd {
    font-size: 16px;
    margin: 5px 0;
}
.title-elementssd {
    font-size: 14px;
    margin: 5px 0;
}
.inicial {
    margin-top: 20px;
}
.tableas {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}
.tableas td, .tableas th {
    border: 1px solid white;
    padding: 8px;
    text-align: left;
}
.tableas th {
    background-color: white;
}
.text-resultss {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
.principaña{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.text-inputasd {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.inputqq {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 5px;
    margin-top: 8px;
}
.buttonqq {
    padding: 10px;
    background-color: #050505;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    margin-top: 10px;
}
.buttonqq:hover {
    background-color: #050505;
}

/* Consulta de medios para dispositivos con ancho inferior a 1000 píxeles */
@media screen and (max-width: 1000px) {
    .inputqq {
        width: 60%;
    }
    .children-titlesd{
        font-size: 0.8em;
    }
    .padree{
        padding-bottom: 20px;
        padding-top: 20px;
    }
}
@media screen and (max-width: 500px) {
    .inputqq {
        width: 40%;
    }
    .children-titlesd{
        font-size: 0.8em;
    }

}