.nutritional-calculator {
  padding: 20px;
}
.titleee {
  text-align: center;
  margin-bottom: 20px;
}
.food-category {
  margin-bottom: 30px;
}
.food-category h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}
.food-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.food-item {
  margin-bottom: 15px;
}
.element-container {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}
.element-name {
  font-weight: bold;
  margin-bottom: 5px;
}
.element-image {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}
.load-more-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
.load-more-button:hover {
  background-color: #45a049;
}
.product-name {
  font-weight: bold;
  height: 70px;
  margin-bottom: 10px;
}
.product-image {
  max-width: 100%;
  height: auto;
}

.simple-product-link {
  text-decoration: none;
  color: inherit; /* Hereda el color del texto del contenedor padre */
}
.simple-products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Para que haya espacio entre los elementos */
}
.simple-product-container {
  flex: 0 0 calc(30% - 20px); /* Ancho del elemento, restando márgenes */
  box-sizing: border-box;
  margin: 25px;
  background-color: #ffea58;
  border-radius: 5px;
  text-align: center;
}
.menu-container {
  width: 80%;
  margin-top: 50px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  background-color: #077140;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .children-title{
    font-size: 0.8em;
  }
  .food-category h2 {
    font-size: 1.2em;
  }

  .simple-products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centra los elementos en dispositivos móviles */
  }

  .simple-product-container {
    flex: 0 0 calc(50% - 20px); /* Ancho del elemento, restando márgenes */
    margin: 10px;
  }
}

/* Estilos para tabletas y dispositivos más grandes */
@media screen and (min-width: 769px) {
  .simple-products-list {
    justify-content: space-between; /* Vuelve al diseño original en tabletas y dispositivos más grandes */
  }

  .simple-product-container {
    flex: 0 0 calc(30% - 20px); /* Ancho del elemento, restando márgenes */
  }
}

/* Estilos comunes */
.food-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
